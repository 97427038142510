import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Background, Parallax } from "react-parallax"
import {
  FaEnvelope,
  FaShareAlt,
  FaPhone,
  FaMapMarker,
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin, FaInstagram
} from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, Row, Col } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps"
import StrataDocumentsModal from "./documents/strata-documents-modal"
import TitleHeader from "../components/title-header"

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 49.2861299, lng: -123.12784490000001 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 49.2861579, lng: -123.12550060000001 }} />}
  </GoogleMap>
))

const ContactPage = ({ data }) => {
  const contactData = data.contentfulContactPage
  const officeNum = contactData.officePhone
  const officeNumShort = officeNum.replace(/ /g, '').replace(/-/g, '')

  return (
    <Layout>
      <SEO title="Contact | Korecki Real Estate Services" description="Get in touch with Korecki Real Estate Services. We provide professional, experienced Strata Management services to clients throughout the Lower Mainland." keywords="korecki,Real Estate,Strata Management services, Alex Korecki, Vesna Korecki, Strata Property Management, Strata Management, Property Management, contact , Client Property Account System, info@korecki.ca, 604 233-7772"/>
      <div>
        <SEO title="Company | Korecki Real Estate Services" description="We are licensed through the Real Estate Council of B.C., and believe in providing personalized service, ongoing communication and maintaining strong working relationships with our clients" keywords="korecki,Real Estate,Strata Management services, Alex Korecki, Vesna Korecki, licensed , Client Property Account System"/>
        <TitleHeader title={"Contact"}/>
        <Grid fluid className="half-section-container feature-section">
          <Col md={6} className="half-section">
            <div className="parallax-half-section">
              <MyMapComponent isMarkerShown
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCw3rCEWdJhxVIHDVytP65mqsSU08vDTqU&libraries=geometry,drawing,places"
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: `600px` }} />}
                              mapElement={<div style={{ height: `100%` }}/>}
              />
            </div>
          </Col>
          <Col md={6} className="half-section blocktext" style={{ backgroundColor: 'rgba(250,250,250,0)' }}>
            <section className="contact-section">
             <Row>
               <Col md={6}>
               <FaMapMarker class="icon"/>
               <h4>Address</h4>
               <div className="custom-text-color-children">
                 {documentToReactComponents(contactData.address.json)}
               </div>
               </Col>
             <Col md={6}>
                <FaPhone class="icon"/>
                <h4>Phone</h4>
                <ul>
                  <li>
                    <span className="title">Office</span>
                    <span className="value"><a href={"tel:" + officeNumShort}>{officeNum}</a></span>
                  </li>
                </ul>
             </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                <FaEnvelope class="fa icon"/>
                <h4>Email</h4>
                <ul>
                  <li>
                    <span className="title">Inquiries</span>
                    <a href={"mailto:" + contactData.generalInquiriesEmail}>{contactData.generalInquiriesEmail}</a>
                  </li>
                  <li>
                    <span className="title">Invoices</span>
                    <a href={"mailto:" + contactData.invoicesEmail}>{contactData.invoicesEmail}</a>
                  </li>
                  <li>
                    <span className="title">Careers</span>
                    <a href={"mailto:" + contactData.careersEmail}>{contactData.careersEmail}</a>
                  </li>
                </ul>
                </Col>
              <Col md={6}>
                <FaShareAlt class="fa icon"/>
                <h4>Social Media</h4>
                <ul>
                  <li><FaFacebookSquare /> <a href="https://www.facebook.com/koreckirealestate/" target="_blank" rel="noreferrer">Facebook</a></li>
                  <li><FaTwitterSquare /> <a href="https://twitter.com/koreckire" target="_blank" rel="noreferrer">Twitter</a></li>
                  <li><FaLinkedin /> <a href="https://ca.linkedin.com/company/korecki-real-estate-services-inc-" target="_blank" rel="noreferrer">LinkedIn</a></li>
                  <li><FaInstagram /> <a href="https://www.instagram.com/koreckire/?hl=en" target="_blank" rel="noreferrer">Instagram</a></li>
                </ul>
              </Col>
              </Row>
            </section>
          </Col>
        </Grid>
      </div>
    </Layout>
  )}

export const query = graphql`
  {
    contentfulContactPage(title: {eq: "Contact"}) {
      title
      address {
        json
      }
      officePhone
      generalInquiriesEmail
      invoicesEmail
      careersEmail
    }
  }`

export default ContactPage;
